<template>
    <div class="init">

        <el-form :model="fromValue" label-width="auto"  ref='form_' class="fromClass"
            :rules='rules' label-position="top">
            <!-- <platformCheck @setTenantId='setTenantId_' :tenantId_1='fromValue.tenantId'/> -->

            <el-form-item :label="$t('currencySet.name1')" >
                <el-select v-model="fromValue.tenantId" :placeholder="$t('inputTit.xuanze')" :teleported='false' :disabled="style_ === 1 || current_tenant_id !== '1'">
                    <el-option :label="item.name" :value="item.id" v-for='item in tenantArray' :key = 'item.id'/>
                </el-select>
            </el-form-item>
            <el-form-item :label='$t("user.text2")' prop="username">
                <el-input v-model="fromValue.username" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>

            <el-form-item :label="$t('user.text3')" prop="password">
                <el-input v-model="fromValue.password" :placeholder="$t('inputTit.shuru')" type='password' show-password/>
            </el-form-item>

            <el-form-item :label="$t('user.text5')" prop="name">
                <el-input v-model="fromValue.name" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <!-- <el-form-item :label="$t('user.text6')" prop="areaCode">
                <el-input v-model="fromValue.areaCode" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item> -->
            <el-form-item :label="$t('user.text7')" prop="phone">
                <el-input v-model="fromValue.phone" :placeholder="$t('inputTit.shuru')">
                    <template #prepend>
                        +
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('user.text11')" prop="email">
                <el-input v-model="fromValue.email" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <!-- <el-form-item :label="$t('user.text10')" prop="lockFlag">
                <el-radio-group v-model="fromValue.lockFlag">
                    <el-radio label="0" size="large" border>{{$t('user.text10_1')}}</el-radio>
                    <el-radio label="9" size="large" border>{{$t('user.text10_2')}}</el-radio>
                </el-radio-group>
            </el-form-item> -->


            <el-form-item :label="$t('user.text8')" prop="deptId">
                <el-tree-select v-model="fromValue.deptId" :placeholder="$t('inputTit.xuanze')"  :teleported='false'
                    :data="deptArray"
                    :render-after-expand="false" check-strictly
                />
            </el-form-item>
            <el-form-item :label="$t('user.text9')" prop="role">
                <el-select v-model="fromValue.role" :placeholder="$t('inputTit.xuanze')" multiple :teleported='false'>
                    <el-option :label="item.roleName" :value="item.roleId" v-for='item in roleArray' :key = 'item.roleId'  />
                </el-select>
            </el-form-item>
            <el-form-item label="Google Code" prop="googleKey">
                <el-input v-model="fromValue.googleCode" :placeholder="$t('inputTit.shuru')" disabled>
                    <template #append>
                        <el-button class="btn" text type="primary" @click="getGoogleKey" 
                            :disabled='fromValue.username?false:true'>生成</el-button>
                        <el-button class="btn" :data-clipboard-text="fromValue.googleCode" text type="primary"
                            :disabled='fromValue.googleCode?false:true'>复制</el-button>
                    </template>
                </el-input>
            </el-form-item>
        </el-form>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineEmits,getCurrentInstance } from 'vue'
    // import addrView from './addr.vue'
    import Api_ from '@/api/index'
    import { ElNotification,ElMessage } from 'element-plus'
    import addRules from './addRules'
    const { $t } = getCurrentInstance().proxy;
    // import platformCheck from '@/components/platformCheck.vue' 
    import ClipboardJS from 'clipboard'
    
    const fromValue = reactive({ 
        userId: '',username: '',password:'',
        name: '',phone: '',
        email: '',lockFlag: '0',deptId: '',role: '',
        googleCode: '',tenantId:''
    })

    const form_ = ref(null)
    const rules = ref(null); rules.value = addRules.mainRules
    const style_ = ref(0)
    const deptArray = ref([]); 
    const roleArray = ref([]); 
    const getPassword = ref('')

    const emit = defineEmits(['successFun','closeFun'])
    import apiList from '@/const/apiList'
    const tenantArray = ref([])
    apiList.getTenantList((data)=>{ tenantArray.value = data })

    import { questStore } from '@/store/quest'
    const current_tenant_id = questStore().getTenantId

    var clipboard = new ClipboardJS('.btn');
    clipboard.on('success', function() {
        ElMessage({
            message: '复制成功',
            type: 'success',
        })
    });
    const getGoogleKey = ()=>{
        Api_.getKeyByGoogle(fromValue.username).then(res=>{
            if(res.code === 0){
                fromValue.googleCode = res.data.googleKey;
            }
        }) 
    }
    const completeFun = ()=>{
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                if(style_.value === 0){
                    if(!fromValue.googleCode){
                        ElNotification({
                            message: '请填写Google Code',
                            type: 'error',
                        })
                        return 0
                    }
                    let obj_ = JSON.parse(JSON.stringify(fromValue))
                    delete obj_.userId
                    Api_.addUser(obj_).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succAdd'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    if(fromValue.password === '******'){
                        fromValue.password = undefined
                    }
                    Api_.updateUser(fromValue).then(res=>{
                        fromValue.password = '******'
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succUpdate'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }
        })
    }
    const init_ = (e,obj,deptArray1,roleArray1)=>{//初始化数据
        style_.value = e;
        deptArray.value = deptArray1
        roleArray.value = roleArray1
        if(obj){
            for(let index in fromValue){
                fromValue[index] = obj[index]
            }
            getPassword.value = obj.password
            fromValue.password = '******'
            fromValue.role = obj.roleList.map(function(item){return item.roleId})
        }else{
            for(let index in fromValue){
                if(index === 'tenantId' && current_tenant_id !== '1'){
                    fromValue['tenantId'] = current_tenant_id
                }else{
                    fromValue[index] = ''
                }
            }
        }
    }
    defineExpose({init_,completeFun})
</script>